/* #configurations {
    margin: 10px auto; 
    align-content: center;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    border-collapse: collapes;
    border: 3px solid #ddd;
    width: 90%
}

#configurations td, #configurations th {
    border: 1px solid #ddd;
    padding: 8px;
}

#configurations tr:nth-child(even){background-color: #f2f2f2;}

#configurations tr:hover {background-color: #ddd;}

#configurations th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #9c9998;
  color: white;
} */

/* td {
  word-wrap:break-word;
} */

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

/* .Table th{
    background-color: rgb(235, 235, 235);
} */
.stateDesc th{
  background-color: rgb(183, 186, 187);
}
/* .stateDesc td{
  background: rgb(255, 255, 255);
} */
/* .stateDesc tr{
  background-color: rgb(255, 255, 255);
} */

/* .Table tr:nth-child(even) {
  background-color: rgb(245, 245, 245);
}
.Table tr:nth-child(odd) {
  background-color: rgb(255, 255, 255);
} */

td {
  word-wrap:break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  text-align: left;
}

Table {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}