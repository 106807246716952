.dashboard-title {
    margin: 40px;
    font-size: 40px;
}

.dashboard-button-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
