/* .color-embross-dark {
    background-color: #08253B;
} */

.logOff{
    margin-left:40px;
}

.error{
    position: relative;
    right: 240px;
    color: #DD6973;
    font-weight: bold;
}

.asterisk {
    color: #D9374B;
    display: inline;
}

/* body {
    font-family: poppins;
} */

.plusRow {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    font-size: 15px;
    line-height: 1.42857;
    /*margin-right: 10px;*/
    /* margin-top:4px; */
    /* margin-left: 30px; */
}

.plusRow2 {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    font-size: 15px;
    line-height: 1.42857;
    margin-top:5px;
    /*margin-left: 30px;*/
}

.minusRow {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    font-size: 15px;
    line-height: 1.42857;
    margin-top:4px;
}

.minusRow2 {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    font-size: 15px;
    line-height: 1.42857;
    /* margin-top:5px; */
    /*margin-left: 30px;*/
    color: #D9374B;

}

.minusRow2:hover {
    color: rgb(114, 7, 20);
}

.dropdown-menu{
    max-height: 200px;
    overflow-y: auto;
}

.centerIcon {
    text-align: center;
}

.bagTitle {
    font-family: poppins;
    font-weight: bold;
    text-align: center;
    margin-left: 30px;
}

.bagInput {
    text-align: center;
}

.bagNumber {
    text-align: center;
    /*margin-left: 25px;*/
    font-family: poppins;
    /* font-weight: bold; */
    /* text-align: center; */
}

.bagType {
    margin-top: 10px;
}
.carryon {
    margin-top: 10px;
}

.profileaccess {
    margin: 7px 0 0 30px;
}

.DOB {
    margin-top: 4px;
    color: #3F5476;
}

.onTime {
    font-family: poppins;
    font-weight: bold;
    color: green;
    display: inline;

}

.notOnTime {
    font-family: poppins;
    font-weight: bold;
    color: #D9374B;
    display: inline;

}

.flightDesc {
    font-family: poppins;
    display: inline;
}

.footer {
    text-align: left;
    /* position: fixed; */
    bottom: 0;
}

.react-date-picker__wrapper {
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.react-date-picker__calendar {
    z-index: 5 !important;
}
